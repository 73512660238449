import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '10px',
		// border: `1px solid ${theme.workwaveBlue}`,
		padding: 0,

		'&:hover': {
			cursor: 'pointer',
			'& $arrow': { transform: 'translateX(10px)' },
		},
		[theme.breakpoints.between('md', 'lg')]: {
			height: '400px',
		},
	},
	media: {
		height: '100%',
		width: '100%',
		background: theme.workwaveBlue,
	},
	text: {
		color: theme.workwaveBlue,
		opacity: '1',
		textDecoration: 'none',
		transition: 'opacity .25s ease-in-out',
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
}));

export const BlogCard = ({ card }) => {
	const classes = useStyles();
	const { image, title, blurb, cta, slug } = card.node;
	return (
		<a
			href={slug.current}
			target='_blank'
			rel='noopener noreferrer'
			style={{ textDecoration: 'none' }}>
			<Card className={classes.root}>
				<Grid container direction='column'>
					<GatsbyImage
						image={image?.asset?.gatsbyImageData}
						className={classes.media}
						alt='blog-header'
					/>
					{/* <Box
					component='div'
					style={{
						background: '#F5F5F6',
						borderRadius: '10px',
						width: '30%',
						textAlign: 'center',
					}}>
					{card.node.type.title}
				</Box> */}
				</Grid>
				<CardContent>
					<Grid
						container
						direction='column'
						justify='space-between'
						alignItems='flex-start'>
						<Typography
							gutterBottom
							variant='h5'
							style={{ fontWeight: 600 }}
							className={classes.text}>
							{title}
						</Typography>
						<Typography variant='body2' className={classes.text} gutterBottom>
							{blurb}
						</Typography>
						<Typography
							variant='body2'
							className={classes.text}
							style={{ fontWeight: 600 }}>
							{cta} <span className={classes.arrow}>→</span>
						</Typography>
					</Grid>
				</CardContent>
			</Card>
		</a>
	);
};
