import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		fontWeight: 600,
	},
	imgCont: {
		paddingTop: '4rem',
		paddingBottom: '2rem',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '2rem',
			paddingBottom: '1rem',
		},
	},
}));

export const Hero = () => {
	const classes = useStyles();
	const { hero } = useStaticQuery(graphql`
		query {
			hero: sanityTopHero {
				title
				slug {
					current
				}
				tags
				titleText
				_rawSubtitleText
				ctaText
				backgroundImage {
					asset {
						gatsbyImageData(
							fit: FILLMAX
							height: 200
							width: 1200
							placeholder: BLURRED
						)
					}
				}
			}
		}
	`);
	return (
		<div
			style={{
				backgroundImage: `url(${hero.backgroundImage.asset.gatsbyImageData.images.fallback.src})`,
				backgroundSize: 'cover',
			}}
			className={classes.imgCont}>
			<Container fixed>
				<Grid
					container
					item
					direction='column'
					justify='center'
					alignItems='flex-start'
					xs={12}>
					<Typography
						variant='h3'
						className={classes.text}
						style={{
							textTransform: 'uppercase',
						}}>
						{hero.titleText}
					</Typography>
					<Typography variant='h4'>
						{hero._rawSubtitleText.map((content, index) => (
							<PortableText
								key={index}
								content={content}
								className={classes.text}
							/>
						))}
					</Typography>
				</Grid>
			</Container>
		</div>
	);
};
