import React, { useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
	Card,
	Typography,
	FormGroup,
	Checkbox,
	FormControlLabel,
	Box,
	AccordionSummary,
	AccordionDetails,
	Accordion,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import TokenContext from '../../context/TokenContext';

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: 'none',
		borderRadius: '8px',
		padding: 0,
		marginBottom: '2rem',
	},
	title: {
		fontSize: 22,
		fontWeight: 600,
		color: theme.white,
		textTransform: 'uppercase',
	},
	titleBackground: {
		background: theme.workwaveBlue,
		color: theme.white,
	},
	checkboxes: {
		color: theme.workwaveBlue,
		marginTop: '.5rem',
	},
}));

export const CheckboxCard = ({
	items,
	criteria,
	handleChange,
	title,
	handleUserPresetSelections,
}) => {
	const { user } = useContext(TokenContext);
	const classes = useStyles();

	useEffect(() => {
		if (user) {
			let preset;
			if (user.Product === 'PP') {
				preset = {
					name: 'PestPac',
					checked: true,
				};
				handleUserPresetSelections(preset);
			} else if (user.Product === 'RG') {
				preset = {
					name: 'Real Green',
					checked: true,
				};
				handleUserPresetSelections(preset);
			}
		}
	}, [user]);
	return (
		<Card className={classes.root} variant='outlined'>
			<Accordion defaultExpanded>
				<Box component='section' className={classes.titleBackground}>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon
								className={classes.title}
								fontSize='large'
								style={{ fontSize: '32px', fontWeight: 600, margin: 0 }}
							/>
						}
						style={{ margin: 0 }}>
						<Typography className={classes.title} style={{ margin: 0 }}>
							{title}
						</Typography>
					</AccordionSummary>
				</Box>
				<AccordionDetails>
					<FormGroup className={classes.checkboxes}>
						{items.map((item) => {
							return (
								<FormControlLabel
									control={
										<Checkbox
											checked={item.checked}
											disabled={item.disabled}
											onChange={(e) => handleChange(e)}
											name={item.name}
											criteria={criteria}
											color='primary'
										/>
									}
									key={item.id}
									label={item.name}
									style={{ fontWeight: 'bold' }}
								/>
							);
						})}
					</FormGroup>
				</AccordionDetails>
			</Accordion>
		</Card>
	);
};
