import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Menu, IconButton, Box, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { CheckboxCard } from './CheckboxCard';

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: 'none',
		borderRadius: '8px',
		padding: 0,
		marginBottom: '2rem',
	},
	title: {
		fontSize: 24,
		fontWeight: 'bolder',
		color: theme.white,
		textTransform: 'uppercase',
	},
	titleBackground: {
		background: theme.workwaveBlue,
		color: theme.white,
		padding: '.5rem',
		borderRadius: '10px',
	},
	checkboxes: {
		color: theme.workwaveBlue,
	},
	button: {
		color: theme.white,
	},
	icon: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	closeIcon: {
		color: theme.workwaveBlue,
		fontSize: 40,
		marginBottom: '1rem',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	notMobile: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
}));

const ITEM_HEIGHT = 84;

export const Checkboxes = ({
	boxes,
	reset,
	handleChangeProd,
	handleChangeInd,
	handleChangeCat,
	handleUserPresetSelections,
}) => {
	const classes = useStyles();
	const { industries, products, categories } = boxes;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileClose = () => {
		setAnchorEl(null);
	};

	const lg = useMediaQuery('(max-width: 1280px)');
	return (
		<>
			{lg ? (
				<div>
					<Box
						component='section'
						className={classes.titleBackground}
						onClick={handleClick}>
						<Grid
							container
							direction='row'
							justify='flex-start'
							alignItems='center'>
							<IconButton
								aria-label='more'
								aria-controls='long-menu'
								aria-haspopup='true'>
								<MenuIcon style={{ color: 'white' }} />
							</IconButton>
							<Typography variant='h5' className={classes.title}>
								Filters
							</Typography>
						</Grid>
					</Box>
					<Menu
						id='long-menu'
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleMobileClose}
						PaperProps={{
							style: {
								maxHeight: ITEM_HEIGHT * 4.5,
								padding: '1rem',
								background: '#F1F2F5',
								width: '100%',
							},
						}}>
						<Grid container item direction='row' justify='flex-end'>
							<CloseIcon
								onClick={handleMobileClose}
								className={classes.closeIcon}
							/>
						</Grid>

						<CheckboxCard
							items={industries}
							criteria='industries'
							handleChange={handleChangeInd}
							title='Industries'
							handleUserPresetSelections={handleUserPresetSelections}
						/>
						<CheckboxCard
							items={products.sort((a, b) => a.name.localeCompare(b.name))}
							criteria='products'
							handleChange={handleChangeProd}
							title='Products'
							handleUserPresetSelections={handleUserPresetSelections}
						/>
						<CheckboxCard
							items={categories}
							criteria='categories'
							handleChange={handleChangeCat}
							title='Categories'
							handleUserPresetSelections={handleUserPresetSelections}
						/>
					</Menu>
				</div>
			) : (
				<div className={classes.notMobile}>
					<CheckboxCard
						items={industries}
						criteria='industries'
						handleChange={handleChangeInd}
						title='Industries'
						handleUserPresetSelections={handleUserPresetSelections}
					/>
					<CheckboxCard
						items={products.sort((a, b) => a.name.localeCompare(b.name))}
						criteria='products'
						handleChange={handleChangeProd}
						title='Products'
						handleUserPresetSelections={handleUserPresetSelections}
					/>
					<CheckboxCard
						items={categories}
						criteria='categories'
						handleChange={handleChangeCat}
						title='Categories'
						handleUserPresetSelections={handleUserPresetSelections}
					/>
				</div>
			)}
		</>
	);
};
