import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';

import { Grid, Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FilterCard } from '../components/HomePage/FilterCard';
import { Checkboxes } from '../components/HomePage/Checkboxes';
import { Hero } from '../components/HomePage/Hero';
import { BlogsAndResources } from '../components/HomePage/BlogsAndResources';
import { FeaturedProducts } from '../components/HomePage/FeaturedProducts';
import { SEO } from '../components/SEO';
import { setCheckboxOptions } from '../utils/setCheckboxOptions';
import TokenContext from '../context/TokenContext';

const useStyles = makeStyles((theme) => ({
	sectionHeader: {
		color: theme.workwaveBlue,
		fontWeight: 'bold',
		textTransform: 'uppercase',
	},
	[theme.breakpoints.down('xl')]: {
		filterContainer: {
			'& > div:nth-child(3n)': {
				marginRight: '0px',
			},
		},
	},
	[theme.breakpoints.down('sm')]: {
		filterContainer: {
			'& > div:nth-child(3n)': {
				marginRight: '25px',
			},
			'& > div:nth-child(2n)': {
				marginRight: '0px',
			},
		},
	},
	[theme.breakpoints.down('xs')]: {
		filterContainer: {
			'& > div:nth-child(3n)': {
				marginRight: '0px',
			},
			'& > div:nth-child(2n)': {
				marginRight: '0px',
			},
		},
	},
}));

const IndexPage = ({ data, location }) => {
	const { setToken } = useContext(TokenContext);
	const classes = useStyles();
	//underscore just indicates they're semantically different from stateful values.
	const _addons = data.addons.edges;
	const _categories = data.categories.edges;
	const _industries = data.industries.edges;
	const _products = data.products.edges;
	const _partners = data.partners.edges;
	const _featuredProducts = data.featuredProducts.edges;
	const _featuredProductCombos = data.featuredProductCombos.edges;

	//initial state
	const [boxes, setBoxes] = useState({
		addons: [],
		industries: [],
		products: [],
		categories: [],
		partners: [],
	});
	//all items set to displayed: false by default. This piece of state allows them all to be displayed on mount. baseBoxes sets a copy of original state
	const [defaultOpen, setDefaultOpen] = useState(true);
	const [baseBoxes, setBaseBoxes] = useState(null);
	const [filters, setFilters] = useState({
		category: null,
		standard: [],
	});
	const [featuredFilters, setFeaturedFilters] = useState([]);
	const [fp1, setFp1] = useState(_featuredProducts[0].node);
	const [fp2, setFp2] = useState(_featuredProducts[2].node);

	useEffect(() => {
		//huge function that was moved to a utils file to keep this more succint. Open to suggestions on this. Can become a hook.
		setCheckboxOptions(
			_addons,
			_industries,
			_categories,
			_products,
			_partners,
			setBoxes,
			boxes,
			setBaseBoxes
		);

		setToken(queryString.parse(location.search).token);
	}, []);

	useEffect(() => {
		//sets the products back to their original state if all products are deselected and the default flag isn't true
		if (
			boxes.industries.length &&
			boxes.products.length &&
			boxes.categories.length
		) {
			const { categories, industries, products } = boxes;
			const allFalseDefaultCategory = categories.every((e) => {
				return !e.checked;
			});
			const allFalseDefaultIndustry = industries.every((e) => {
				return !e.checked;
			});
			const allFalseDefaultProduct = products.every((e) => {
				return !e.checked;
			});
			if (
				boxes &&
				!defaultOpen &&
				allFalseDefaultCategory &&
				allFalseDefaultIndustry &&
				allFalseDefaultProduct
			) {
				setBoxes({
					...baseBoxes,
				});
				setDefaultOpen(true);
			}
		}
	}, [boxes]);

	useEffect(() => {
		//checks for length of standard filter. Makes a copy of standard filter array and adds the value of the category filter, if it exists. Sets the boxes and
		//addon products and partners to their proper state of displayed card or disabled checkbox based on the given combination of filters. sets the
		//modifiedFilters array back to empty, sets state with the new value. filters is in dep array to track filter changes and fire function.
		if (filters.standard.length || filters.category) {
			let addons;
			let partners;
			let modifiedFilters = [...filters.standard];

			if (filters.category) {
				modifiedFilters = [...modifiedFilters, filters.category];
			}

			setFeaturedFilters([...modifiedFilters]);

			addons = boxes.addons.map((addon) => {
				if (
					modifiedFilters.every((filter) => addon.available.includes(filter))
				) {
					return { ...addon, displayed: true };
				} else {
					return { ...addon, displayed: false };
				}
			});

			partners = boxes.partners.map((partner) => {
				if (
					modifiedFilters.every((filter) => partner.available.includes(filter))
				) {
					return { ...partner, displayed: true };
				} else {
					return { ...partner, displayed: false };
				}
			});

			const industries = boxes.industries.map((industry) => {
				if (
					modifiedFilters.every((filter) => industry.available.includes(filter))
				) {
					return { ...industry, disabled: false };
				} else {
					return { ...industry, disabled: true };
				}
			});

			const products = boxes.products.map((product) => {
				if (
					modifiedFilters.every((filter) => product.available.includes(filter))
				) {
					return { ...product, disabled: false };
				} else {
					return { ...product, disabled: true };
				}
			});

			const categories = boxes.categories.map((category) => {
				if (
					modifiedFilters.every((filter) => category.available.includes(filter))
				) {
					return { ...category, disabled: false };
				} else {
					return { ...category, disabled: true };
				}
			});

			modifiedFilters = [];

			setBoxes({
				...boxes,
				addons,
				partners,
				industries,
				products,
				categories,
			});
		}
	}, [filters]);

	const filter = (name, checked, criteria) => {
		//sets the toggle for displaying everything to false.
		setDefaultOpen(false);

		//checks to see if the criteria is category or not. If it is, it simply changes the value of category in state to the new category value.
		//if it is not, it modifies the filter array to either add or remove the value from the standard filter array
		if (checked) {
			if (criteria === 'category') {
				setFilters({
					...filters,
					category: name,
				});
			} else {
				setFilters({
					...filters,
					standard: [...filters.standard, name],
				});
			}
		} else {
			const removeStandard = filters.standard.filter((item) => item !== name);
			if (criteria === 'category') {
				setFilters({
					...filters,
					category: null,
				});
			} else {
				setFilters({
					...filters,
					standard: [...removeStandard],
				});
			}
		}
	};

	//loops through the options for each checkbox array and sets the item where the selected name matches name of the item to the checked value(boolean that toggles)
	//passes the criteria as a string, the updated value for the given criteria, the name selected, and the checked boolean value to the fitler function above. category
	//can only receive a single value, so it sets one to true and the rest to false on change
	const handleChangeProd = (e) => {
		const { name, checked } = e.target;

		let updated = boxes.products.map((prod) => {
			if (prod.name === e.target.name) {
				return { ...prod, checked };
			} else {
				return { ...prod };
			}
		});

		setBoxes({
			...boxes,
			products: updated,
		});
		filter(name, checked, 'products');
	};

	const handleChangeCat = (e) => {
		const { name, checked } = e.target;

		let updated = boxes.categories.map((cat) => {
			if (cat.name === name) {
				return { ...cat, checked: !cat.checked };
			} else {
				return { ...cat, checked: false };
			}
		});

		setBoxes({
			...boxes,
			categories: updated,
		});
		filter(name, checked, 'category');
	};

	const handleChangeInd = (e) => {
		const { name, checked } = e.target;

		let updated = boxes.industries.map((industry) => {
			if (industry.name === name) {
				return { ...industry, checked };
			} else {
				return { ...industry };
			}
		});

		setBoxes({
			...boxes,
			industries: updated,
		});
		filter(name, checked, 'industries');
	};

	//used specifically for setting custom settings on mount(ie: client comes from pestpac, pp should be checked)
	const handleUserPresetSelections = (presets) => {
		const { name, checked } = presets;

		let updated = boxes.products.map((prod) => {
			if (prod.name === presets.name) {
				return { ...prod, checked };
			} else {
				return { ...prod };
			}
		});

		setBoxes({
			...boxes,
			products: updated,
		});
		filter(name, checked, 'products');
	};

	//resets everything to default. not being used here, specifically, because we've removed the reset all buttons, but can be implemented, if necessary
	const reset = (e) => {
		e.preventDefault();
		setBoxes({ ...baseBoxes });
		setDefaultOpen(true);
		setFilters({
			category: null,
			standard: [],
		});
	};

	const med = useMediaQuery('(max-width: 960px)');
	return (
		<>
			<SEO
				title={data.home.metaTitle}
				description={data.home.metaDescription}
			/>
			<Hero />
			<Container style={{ marginTop: '2rem', marginBottom: '3rem' }} fixed>
				<Grid
					container
					direction='row'
					justify='center'
					alignItems='flex-start'
					spacing={3}>
					<Grid item xs={12} lg={3}>
						<Checkboxes
							boxes={boxes}
							reset={reset}
							handleChangeProd={handleChangeProd}
							handleChangeCat={handleChangeCat}
							handleChangeInd={handleChangeInd}
							handleUserPresetSelections={handleUserPresetSelections}
						/>
					</Grid>
					<Grid item xs={12} lg={9}>
						<FeaturedProducts
							featuredProducts={_featuredProducts}
							featuredFilters={featuredFilters}
							featuredProductCombos={_featuredProductCombos}
							fp1={fp1}
							fp2={fp2}
							setFp1={setFp1}
							setFp2={setFp2}
						/>
						{boxes.addons.some((box) => box.displayed) ? (
							<Typography
								variant='h5'
								gutterBottom
								className={classes.sectionHeader}>
								{_addons[0].node.filterHeader}
							</Typography>
						) : defaultOpen ? (
							<Typography
								variant='h5'
								gutterBottom
								className={classes.sectionHeader}>
								{_addons[0].node.filterHeader}
							</Typography>
						) : null}
						<Grid
							container
							item
							direction='row'
							justify={med ? 'center' : 'flex-start'}
							className={classes.filterContainer}>
							{!!boxes.addons.length
								? boxes.addons.map((addon) => {
										if (addon.displayed || defaultOpen) {
											return (
												<FilterCard
													key={addon.id}
													cardType='addons'
													displayed={addon.displayed}
													name={addon.name}
													categories={addon.categories}
													icon={addon.filterIcon}
													body={addon.filterBody}
													wwProductLogo={addon.wwProductVariant}
													vendor={addon.vendors}
													slug={addon.slug}
													promo={addon.promo}
												/>
											);
										}
								  })
								: null}
							<Grid
								item
								container
								direction='row'
								justify='flex-start'
								style={{ marginLeft: med ? '1rem' : null }}
								spacing={1}>
								{boxes.partners.some((box) => box.displayed) ? (
									<Typography
										variant='h5'
										gutterBottom
										className={classes.sectionHeader}
										style={{
											marginTop: '1rem',
											marginBottom: '1rem',
										}}>
										{_partners[0].node.filterHeader}
									</Typography>
								) : defaultOpen ? (
									<Typography
										variant='h5'
										gutterBottom
										className={classes.sectionHeader}
										style={{
											marginTop: '1rem',
											marginBottom: '1rem',
										}}>
										{_partners[0].node.filterHeader}
									</Typography>
								) : null}
							</Grid>

							<Grid
								item
								container
								direction='row'
								justify={med ? 'center' : 'flex-start'}
								className={classes.filterContainer}>
								{!!boxes.partners.length
									? boxes.partners.map((partner) => {
											if (partner.displayed || defaultOpen) {
												return (
													<FilterCard
														key={partner.id}
														cardType='partners'
														displayed={partner.displayed}
														name={partner.name}
														icon={partner.filterLogo}
														body={partner.filterBody}
														slug={partner.slug}
														promo={partner.promo}
														affiliateLink={partner.affiliateLink}
													/>
												);
											}
									  })
									: null}
							</Grid>
							{!defaultOpen &&
								!boxes.partners.some((box) => box.displayed) &&
								!boxes.addons.some((box) => box.displayed) && (
									<Grid
										container
										direction='column'
										justify='center'
										alignItems='center'>
										<Typography variant='h5' style={{ textAlign: 'center' }}>
											No matching products or partners returned. Deselect some
											filters or click reset.
										</Typography>
										<Button
											variant='contained'
											color='primary'
											onClick={(e) => reset(e)}>
											Reset
										</Button>
									</Grid>
								)}
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<BlogsAndResources />
		</>
	);
};

export const query = graphql`
	query HomePageQuery {
		addons: allSanityAddon(filter: { i18n_lang: { eq: "en" } }) {
			edges {
				node {
					_id
					title
					slug {
						current
					}
					tags
					filterIcon
					filterHeader
					filterBody
					wwProductVariant {
						filterLogo {
							asset {
								url
								gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, height: 50)
							}
						}
					}
					promo {
						_rawPromotion
						_rawDisclaimer
					}
					categories {
						title
					}
					industries {
						title
					}
					products {
						title
					}
					partners {
						title
					}
				}
			}
		}
		categories: allSanityCategories {
			edges {
				node {
					_id
					title
					products {
						title
					}
					industries {
						title
					}
				}
			}
		}
		industries: allSanityIndustries {
			edges {
				node {
					_id
					title
					categories {
						title
					}
					products {
						title
					}
				}
			}
		}
		products: allSanityProducts {
			edges {
				node {
					_id
					title
					categories {
						title
					}
					industries {
						title
					}
				}
			}
		}
		partners: allSanityPartners(filter: { i18n_lang: { eq: "en" } }) {
			edges {
				node {
					_id
					title
					slug {
						current
					}
					affiliateLink
					filterHeader
					filterLogo {
						asset {
							gatsbyImageData(
								layout: CONSTRAINED
								placeholder: BLURRED
								height: 50
							)
						}
					}
					filterBody
					promo {
						_rawPromotion
						_rawDisclaimer
					}
					categories {
						title
					}
					industries {
						title
					}
					products {
						title
					}
				}
			}
		}
		featuredProducts: allSanityFeaturedProducts {
			edges {
				node {
					_id
					_key
					title
					slug {
						current
					}
					fullName
					background {
						asset {
							url
							gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
						}
					}
					body
					ctaText
				}
			}
		}
		featuredProductCombos: allSanityFeaturedProductCombos {
			edges {
				node {
					_id
					title
					combos
					fp1
					fp2
				}
			}
		}
		home: sanityHome {
			metaTitle
			metaDescription
		}
	}
`;

export default IndexPage;
