import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Container } from '@material-ui/core';

import { BlogCard } from './BlogCard';

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.workwaveBlue,
		paddingTop: '3rem',
		paddingBottom: '6rem',
	},
	text: {
		color: theme.white,
		textAlign: 'center',
	},
}));

export const BlogsAndResources = () => {
	const classes = useStyles();
	const { cards } = useStaticQuery(graphql`
		query {
			cards: allSanityResources {
				edges {
					node {
						_id
						title
						sectionTitle
						sectionBody
						type {
							title
						}
						slug {
							current
						}
						blurb
						cta
						image {
							asset {
								gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
							}
						}
					}
				}
			}
		}
	`);

	const contentCards = cards.edges;
	return (
		<Box component='div' className={classes.root}>
			<Container fixed>
				<Grid container direction='row' justify='center' alignItems='center'>
					<Grid item xs={12}>
						<Typography
							variant='h3'
							gutterBottom
							className={classes.text}
							style={{ fontWeight: 'bold' }}>
							{contentCards[0].node.sectionTitle}
						</Typography>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Typography
							variant='body1'
							gutterBottom
							className={classes.text}
							style={{ marginBottom: '3rem' }}>
							{contentCards[0].node.sectionBody}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={3}
					direction='row'
					alignItems='center'
					justify='center'>
					{contentCards.map((card, index) => (
						<Grid item xs={12} md={4} key={index}>
							<BlogCard card={card} />
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
};
