import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	img: {
		width: '100%',
		height: '100%',
	},
	container: {
		height: '297px',
		margin: '.5rem',
		padding: '.5rem',
		maxWidth: '85%',
		[theme.breakpoints.down('md')]: {
			height: '346px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '280px',
		},
	},
	root: {
		borderRadius: '10px',
		padding: 0,
		transition: 'all 1s',
		background: theme.white,
		'&:hover': {
			transform: 'scale(1.02)',
			transition: 'all 0.25s',
			cursor: 'pointer',
			boxShadow:
				'0 13px 27px -5px rgba(50, 50, 93, 0.2), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
			'& $arrow': { transform: 'translateX(10px)' },
		},
	},
	text: {
		color: theme.white,
		zIndex: 10,
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
}));

export const FeaturedProducts = ({
	featuredProducts,
	featuredFilters,
	featuredProductCombos,
	fp1,
	fp2,
	setFp1,
	setFp2,
}) => {
	const classes = useStyles();

	useEffect(() => {
		//this is the filtering logic for the cards. if this stops working, check sanity to make sure nothing is misspelled.
		//as of this code, sanity does not allow for multidimensional arrays. The combos, therefore, are handtyped.
		if (featuredFilters.length) {
			featuredProductCombos.map((combination) => {
				if (
					JSON.stringify(combination.node.combos.sort()) ==
					JSON.stringify(featuredFilters.sort())
				) {
					const newfp1 = featuredProducts.filter(
						(product) => product.node.title === combination.node.fp1
					);
					const newfp2 = featuredProducts.filter(
						(product) => product.node.title === combination.node.fp2
					);
					setFp1(newfp1[0].node);
					setFp2(newfp2[0].node);
				}
			});
		}
	}, [featuredFilters]);

	const handleClick = (e, fp) => {
		navigate(`/${fp.slug.current}`);
	};
	return (
		fp1 &&
		fp2 && (
			<Grid
				container
				direction='row'
				alignItems='center'
				justify='flex-start'
				spacing={3}
				style={{ marginBottom: '1rem' }}>
				<Grid item xs={12} md={6}>
					<Card
						className={classes.root}
						style={{
							backgroundImage: `url(${fp1.background.asset.gatsbyImageData.images.fallback.src})`,
							backgroundSize: 'cover',
						}}
						onClick={(e) => handleClick(e, fp1)}>
						{/* <BgImage
							image={fp1.background.asset.gatsbyImageData}
							style={{ border: '1px solid' }}
							className={classes.img}> */}
						<Grid
							container
							direction='column'
							alignItems='flex-start'
							justify='flex-end'
							className={classes.container}>
							<Typography
								gutterBottom
								variant='h5'
								style={{ fontWeight: 600 }}
								className={classes.text}>
								{fp1?.fullName}
							</Typography>
							<Typography
								gutterBottom
								variant='body1'
								style={{ fontWeight: 300 }}
								className={classes.text}>
								{fp1?.body}
							</Typography>

							<Typography
								gutterBottom
								variant='h6'
								className={classes.text}
								style={{ fontWeight: 500 }}>
								{fp1?.ctaText}
								<span className={classes.arrow}>→</span>
							</Typography>
						</Grid>
						{/* </BgImage> */}
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card
						className={classes.root}
						onClick={(e) => handleClick(e, fp2)}
						style={{
							backgroundImage: `url(${fp2.background.asset.gatsbyImageData.images.fallback.src})`,
							backgroundSize: 'cover',
						}}>
						{/* <BgImage
							image={fp2.background.asset.gatsbyImageData}
							style={{ border: '1px solid' }}
							className={classes.img}> */}
						<Grid
							container
							direction='column'
							alignItems='flex-start'
							justify='flex-end'
							className={classes.container}>
							<Typography
								gutterBottom
								variant='h5'
								style={{ fontWeight: 600 }}
								className={classes.text}>
								{fp2?.fullName}
							</Typography>
							<Typography
								gutterBottom
								variant='body1'
								style={{ fontWeight: 300 }}
								className={classes.text}>
								{fp2?.body}
							</Typography>

							<Typography
								gutterBottom
								variant='h6'
								className={classes.text}
								style={{ fontWeight: 500 }}>
								{fp2?.ctaText}
								<span className={classes.arrow}>→</span>
							</Typography>
						</Grid>
						{/* </BgImage> */}
					</Card>
				</Grid>
			</Grid>
		)
	);
};
