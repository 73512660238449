export const setCheckboxOptions = (
	addons,
	industries,
	categories,
	products,
	partners,
	setBoxes,
	boxes,
	setBaseBoxes
) => {
	//sets them in their respective variables with the default mount variables
	const modifiedAddons = addons
		.map((addon) => ({
			id: addon.node._id,
			name: addon.node.title,
			available: [
				...Object.values(addon.node.categories)
					.map(({ title }) => [title])
					.flat(),
				...Object.values(addon.node.industries)
					.map(({ title }) => [title])
					.flat(),
				...Object.values(addon.node.products)
					.map(({ title }) => [title])
					.flat(),
			],
			industries: addon.node.industries,
			products: addon.node.products,
			filterIcon: addon.node.filterIcon,
			filterBody: addon.node.filterBody,
			wwProductVariant: addon.node.wwProductVariant?.filterLogo || null,
			slug: addon.node.slug.current,
			promo: addon.node.promo,
			displayed: false,
		}))
		.filter((addon) => !addon.wwProductVariant)
		.sort((a, b) => a.name.localeCompare(b.name));

	const modifiedLicenses = addons
		.map((addon) => ({
			id: addon.node._id,
			name: addon.node.title,
			available: [
				...Object.values(addon.node.categories)
					.map(({ title }) => [title])
					.flat(),
				...Object.values(addon.node.industries)
					.map(({ title }) => [title])
					.flat(),
				...Object.values(addon.node.products)
					.map(({ title }) => [title])
					.flat(),
			],
			industries: addon.node.industries,
			products: addon.node.products,
			filterIcon: addon.node.filterIcon,
			filterBody: addon.node.filterBody,
			wwProductVariant: addon.node.wwProductVariant?.filterLogo || null,
			slug: addon.node.slug.current,
			promo: addon.node.promo,
			displayed: false,
		}))
		.filter((addon) => addon.wwProductVariant)
		.sort((a, b) => a.slug.localeCompare(b.slug));

	const modifiedPartners = partners
		.map((partner) => ({
			id: partner.node._id,
			name: partner.node.title,
			available: [
				...Object.values(partner.node.categories)
					.map(({ title }) => [title])
					.flat(),
				...Object.values(partner.node.industries)
					.map(({ title }) => [title])
					.flat(),
				...Object.values(partner.node.products)
					.map(({ title }) => [title])
					.flat(),
			],
			categories: partner.node.categories,
			industries: partner.node.industries,
			products: partner.node.products,
			filterLogo: partner.node?.filterLogo?.asset?.gatsbyImageData,
			filterBody: partner.node.filterBody,
			slug: partner.node.slug.current,
			promo: partner.node.promo,
			affiliateLink: partner.node.affiliateLink,
			displayed: false,
		}))
		.sort((a, b) => a.name.localeCompare(b.name));

	const allIndustries = industries.map((industry) => industry.node.title);
	const allProducts = products.map((product) => product.node.title);
	const allCategories = categories.map((category) => category.node.title);

	const modifiedIndustries = industries.map((industry) => ({
		id: industry.node._id,
		name: industry.node.title,
		available: [
			...Object.values(industry.node.categories)
				.map(({ title }) => [title])
				.flat(),
			...Object.values(industry.node.products)
				.map(({ title }) => [title])
				.flat(),
			...allIndustries.flat(),
		],
		categories: industry.node.categories,
		products: industry.node.products,
		checked: false,
		disabled: false,
	}));

	const modifiedCategories = categories.map((category) => ({
		id: category.node._id,
		name: category.node.title,
		available: [
			...Object.values(category.node.products)
				.map(({ title }) => [title])
				.flat(),
			...Object.values(category.node.industries)
				.map(({ title }) => [title])
				.flat(),
			...allCategories.flat(),
		],
		products: category.node.products,
		industries: category.node.industries,
		checked: false,
		disabled: false,
	}));

	const modifiedProducts = products.map((product) => ({
		id: product.node._id,
		name: product.node.title,
		available: [
			...Object.values(product.node.categories)
				.map(({ title }) => [title])
				.flat(),
			...Object.values(product.node.industries)
				.map(({ title }) => [title])
				.flat(),
			...allProducts.flat(),
		],
		categories: product.node.categories,
		industries: product.node.industries,
		checked: false,
		disabled: false,
	}));

	//sets initial state
	setBoxes({
		...boxes,
		addons: [...modifiedAddons, ...modifiedLicenses],
		industries: modifiedIndustries,
		products: modifiedProducts,
		categories: modifiedCategories,
		partners: modifiedPartners,
	});
	//sets a copy of state for default behavior
	setBaseBoxes({
		...boxes,
		addons: [...modifiedAddons, ...modifiedLicenses],
		industries: modifiedIndustries,
		products: modifiedProducts,
		categories: modifiedCategories,
		partners: modifiedPartners,
	});
};
